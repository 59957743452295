/**
 * @file ProfilePage.jsx
 * @author Rishikesh
 * @date 2024-12-12
 * @description Profile Page for the user
 */

import React from "react";
import {
  Box,
  Typography,
  Avatar,
  Grid,
  Button,
  Card,
  CardContent,
  Divider,
  TextField,
} from "@mui/material";
import { useResponsivePadding } from "../../../components/hooks/useResponsivePadding";
import { useFetchData } from "../../../components/hooks/useFetchData";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { setUserInfo } from "../../../store/features/authSlice";

const ProfilePage = () => {
  const padding = useResponsivePadding();
  const { fetchData } = useFetchData();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = React.useState(false);
  const [addresses, setAddresses] = React.useState([]);

  const profileData = useSelector((state) => state.auth.user.info);

  const { register, handleSubmit, reset, getValues } = useForm({
    defaultValues: {
      firstname: "",
      lastname: "",
      email: "",
      phonenumber: "",
      addresses: [],
    },
  });

  const getProfileData = async () => {
    try {
      const response = await fetchData("user/getprofiledetails");
      if (response?.code === 200) {
        const profile = response.data.profiledetails[0];
        dispatch(setUserInfo(profile));
        reset({
          ...getValues(),
          firstname: profile.firstname,
          lastname: profile.lastname,
          email: profile.email,
          phonenumber: profile.phonenumber,
        });
      }
    } catch (error) {
      enqueueSnackbar("Failed to fetch profile data", { variant: "error" });
    }
  };

  const getAddresses = async () => {
    try {
      const response = await fetchData("user/getaddress");
      if (response?.code === 200) {
        setAddresses(response.data);
        reset({ ...getValues(), addresses: response.data });
      }
    } catch (error) {
      enqueueSnackbar("Failed to fetch address data", { variant: "error" });
    }
  };

  const updateProfile = async (data) => {
    try {
      const response = await fetchData("user/updateprofile", {
        method: "POST",
        body: JSON.stringify(data),
      });
      if (response?.code === 200) {
        enqueueSnackbar(response.message, { variant: "success" });
        setIsEditing(false);
        getProfileData();
      }
    } catch (error) {
      enqueueSnackbar("Failed to update profile", { variant: "error" });
    }
  };

  const manageAddress = async (data) => {
    try {
      const response = await fetchData("user/manageaddress", {
        method: "POST",
        body: JSON.stringify(data),
      });
      if (response?.code === 200) {
        enqueueSnackbar(response.message, { variant: "success" });
        getAddresses();
      }
    } catch (error) {
      enqueueSnackbar("Failed to manage address", { variant: "error" });
    }
  };

  const handleProfileData = async (data) => {
    const profileData = {
      firstname: data.firstname,
      lastname: data.lastname,
      phonenumber: data.phonenumber?.toString(),
    };

    const addressData = data.addresses.map((address) => ({
      addressuuid: address.addressuuid || "",
      addressline: address.addressline,
      city: address.city,
      state: address.state,
      pincode: address.pincode,
      country: address.country,
      isdefault: address.isdefault || 0,
      isdelete: address.isdelete || 0,
    }));

    try {
      await updateProfile(profileData);

      await manageAddress({ addresses: addressData });

      await getProfileData();
      await getAddresses();

      setIsEditing(false);
      enqueueSnackbar("Profile updated successfully", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Failed to update profile data", { variant: "error" });
    }
  };

  React.useEffect(() => {
    getProfileData();
    getAddresses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!profileData) {
    return (
      <Box
        px={padding}
        py={4}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h6">Loading profile...</Typography>
      </Box>
    );
  }

  return (
    <Box px={padding} py={4}>
      <Card>
        <CardContent>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={3} textAlign="center">
              <Avatar
                src={profileData.profileImage || "/default-avatar.png"}
                alt={profileData.name}
                sx={{ width: 100, height: 100, margin: "0 auto" }}
              />
              <Typography variant="h6" mt={2}>
                {profileData.firstname || ""} {profileData.lastname || ""}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                {profileData.email}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                {profileData.phonenumber || "N/A"}
              </Typography>
            </Grid>

            <Grid item xs={12} md={9}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6">Address Details</Typography>
              </Box>
              <Divider sx={{ mb: 2 }} />
              {isEditing ? (
                <form onSubmit={handleSubmit(handleProfileData)}>
                  <TextField
                    fullWidth
                    label="First Name"
                    {...register("firstname")}
                    margin="normal"
                  />
                  <TextField
                    fullWidth
                    label="Last Name"
                    {...register("lastname")}
                    margin="normal"
                  />
                  <TextField
                    fullWidth
                    label="Phone Number"
                    {...register("phonenumber")}
                    margin="normal"
                  />
                  {addresses.map((address, index) => (
                    <Box key={index} mb={2}>
                      <TextField
                        fullWidth
                        label="Address Line"
                        defaultValue={address.addressline}
                        {...register(`addresses.${index}.addressline`)}
                        margin="normal"
                      />
                      <TextField
                        fullWidth
                        label="City"
                        defaultValue={address.city}
                        {...register(`addresses.${index}.city`)}
                        margin="normal"
                      />
                      <TextField
                        fullWidth
                        label="State"
                        defaultValue={address.state}
                        {...register(`addresses.${index}.state`)}
                        margin="normal"
                      />
                      <TextField
                        fullWidth
                        label="Pincode"
                        defaultValue={address.pincode}
                        {...register(`addresses.${index}.pincode`)}
                        margin="normal"
                      />
                      <TextField
                        fullWidth
                        label="Country"
                        defaultValue={address.country}
                        {...register(`addresses.${index}.country`)}
                        margin="normal"
                      />
                      <TextField
                        fullWidth
                        label="Address UUID"
                        defaultValue={address.addressuuid}
                        {...register(`addresses.${index}.addressuuid`)}
                        margin="normal"
                        hidden
                      />
                    </Box>
                  ))}
                  <Button type="submit" variant="contained" color="primary">
                    Save Changes
                  </Button>
                </form>
              ) : (
                addresses.map((address, index) => (
                  <Box key={index} mb={2}>
                    <Typography variant="body1">
                      {address.addressline}, {address.city}, {address.state}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {address.pincode}, {address.country}
                    </Typography>
                  </Box>
                )) || (
                  <Typography variant="body2" color="textSecondary">
                    No addresses available.
                  </Typography>
                )
              )}
            </Grid>
          </Grid>

          <Box mt={4} textAlign="center">
            {!isEditing && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setIsEditing(true)}
              >
                Edit Profile
              </Button>
            )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ProfilePage;
