/**
 * @file index.jsx
 * @author Rishikesh
 * @date 2024-10-25
 * @description Exporting all the icons and images
 */
import { AddImage } from "./icons/AddImage";
import { AddShape } from "./icons/AddShape";
import { AddText } from "./icons/AddText";
import { AddVector } from "./icons/AddVector";
import { AlignCenter } from "./icons/AlignCenter";
import { Bold } from "./icons/Bold";
import { Bulb } from "./icons/Bulb";
import { Cart } from "./icons/Cart";
import { Checkmark } from "./icons/Checkmark";
import { Close } from "./icons/Close";
import { Color } from "./icons/Color";
import { Copyright } from "./icons/Copyright";
import { Cutting } from "./icons/Cutting";
import { Delete } from "./icons/Delete";
import { Facebook } from "./icons/Facebook";
import { File } from "./icons/File";
import { Image } from "./icons/Image";
import { Image2 } from "./icons/Image2";
import { ImageUpload } from "./icons/ImageUpload";
import { Instagram } from "./icons/Instagram";
import { Italic } from "./icons/Italic";
import { Logo } from "./icons/Logo";
import { Logout } from "./icons/Logout";
import { Minus } from "./icons/Minus";
import { OrderBox } from "./icons/OrderBox";
import { Person } from "./icons/Person";
import { Plus } from "./icons/Plus";
import { Premium } from "./icons/Premium";
import { Preview } from "./icons/Preview";
import { Replace } from "./icons/Replace";
import { Resize } from "./icons/Resize";
import { Tag } from "./icons/Tag";
import { Whatsapp } from "./icons/Whatsapp";
import { Youtube } from "./icons/Youtube";
import { ZoomIn } from "./icons/ZoomIn";
import { ZoomOut } from "./icons/ZoomOut";
import logo from "./images/logo.png";
import Notebook from "./images/Notebook.png";
import NotebookBanner from "./images/NotebookBanner.png";
import Puzzle from "./images/Puzzle.png";
import Ludo from "./images/Ludo.png";
import PlayingCards from "./images/PlayingCards.png";
import MemoryGame from "./images/MemoryGame.png";

export const Icons = {
  Person,
  OrderBox,
  Cart,
  Facebook,
  Youtube,
  Instagram,
  Whatsapp,
  Image,
  Checkmark,
  Bulb,
  Copyright,
  Cutting,
  File,
  Image2,
  Preview,
  Resize,
  Tag,
  AlignCenter,
  ZoomIn,
  ZoomOut,
  AddImage,
  AddText,
  AddVector,
  AddShape,
  Premium,
  ImageUpload,
  Delete,
  Replace,
  Bold,
  Italic,
  Logout,
  Close,
  Minus,
  Plus,
  Logo,
  Color,
};

export const Images = {
  logo,
  NotebookBanner,
  Notebook,
  Puzzle,
  Ludo,
  PlayingCards,
  MemoryGame,
};
