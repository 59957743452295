/**
 * @file GuidelinesPage.jsx
 * @author Rishikesh
 * @date 2024-10-15
 * @description A page to display Guidelines of the app to customize
 */
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Checkbox,
  Grid2,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { Icons } from "../../../assets";
import { useResponsivePadding } from "../../../components/hooks/useResponsivePadding";

const GuidelinesPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { productDetails } = location.state || {};
  const padding = useResponsivePadding();

  const [isAgreed, setIsAgreed] = useState(false);

  React.useEffect(() => {
    if (productDetails === undefined) {
      navigate("/", { replace: true });
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheckboxChange = (event) => {
    setIsAgreed(event.target.checked);
  };

  const guidelines = [
    {
      id: 1,
      iconName: "Copyright",
      content:
        "Please avoid uploading images of celebrities, sports stars, TV characters, cartoon characters or images for which you do not own the copyrights.",
    },
    {
      id: 2,
      iconName: "Tag",
      content:
        "Please note that the price of your product changes depending on selected specs and designs",
    },
    {
      id: 3,
      iconName: "Cutting",
      content:
        "Please avoid placing any text too close to the border since there are slight chances that it might get cut.",
    },
    {
      id: 4,
      iconName: "File",
      content:
        "Please Check final preview to see if the designs are placed as you wish.",
    },
    {
      id: 5,
      iconName: "Image2",
      content: "Image size should not exceed 10MB & jpeg/png only.",
    },
    {
      id: 6,
      iconName: "Resize",
      content:
        "Please ensure to resize the images as necessary to fit the canvas. You can open the canvas & resize the image.",
    },
    {
      id: 7,
      iconName: "Preview",
      content: "Please Check final preview to see if orientation is correct.",
    },
  ];

  return (
    <Box pt={4}>
      {/* Breadcrums to go back to the previews pages */}
      <Breadcrumbs sx={{ mx: padding }}>
        <Link onClick={() => navigate("/", { replace: true })}>Home</Link>
        <Link to={-1}>Customize</Link>
        <Typography fontWeight={500} color="primary.main">
          Guidelines
        </Typography>
      </Breadcrumbs>
      {/* Heading of the grid section */}
      <Stack
        display="flex"
        mt={2}
        direction="row"
        alignItems="center"
        spacing={2}
        mx={padding}
      >
        <Icons.Bulb />
        <Typography variant="h5" fontWeight={600}>
          Quick tips before you start
        </Typography>
      </Stack>
      {/* Listing the guidelines */}
      <Box mx={padding} mb={3}>
        <Grid2 container justifyContent="center" spacing={2}>
          {guidelines.map((item) => (
            <Grid2 xs={12} sm={6} md={4} key={item.id}>
              <Card
                variant="outlined"
                sx={{ maxWidth: "304px", height: "160px", p: 2 }}
              >
                <Stack alignItems="center" textAlign="center" spacing={2}>
                  {React.createElement(Icons[item.iconName])}
                  <Typography fontSize={12} fontWeight={400}>
                    {item.content}
                  </Typography>
                </Stack>
              </Card>
            </Grid2>
          ))}
        </Grid2>
      </Box>
      {/* Approval section for user has readed the terms & conditions */}
      <Stack
        width="100%"
        spacing={2}
        bgcolor="background.paper"
        textAlign="center"
        p={"30px"}
      >
        <Typography variant="h4" fontWeight={600}>
          Agree Terms and Conditions
        </Typography>
        <Typography fontWeight={400}>
          Read Terms and Conditions before you agree
        </Typography>
        <Button
          onClick={() => {
            window.open(`${process.env.PUBLIC_URL}/#`, "_blank");
          }}
          color="#A88F35"
          size="large"
          sx={{
            alignSelf: "center",
            textTransform: "none",
            textDecoration: "underline",
            textUnderlineOffset: "8px",
            "&:hover": {
              textDecoration: "underline",
              textUnderlineOffset: "8px",
            },
          }}
        >
          Read Terms and Conditions
        </Button>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Checkbox
            checked={isAgreed}
            onChange={handleCheckboxChange}
            color="primary"
          />
          <Typography>I agree to these terms</Typography>
        </Stack>
        <Button
          variant="contained"
          sx={{
            alignSelf: "center",
            textTransform: "none",
            px: 4,
            py: 1,
            borderRadius: "8px",
            fontSize: 18,
          }}
          disabled={!isAgreed}
          onClick={() => navigate("/summary", { state: { productDetails } })}
        >
          Go to Customization
        </Button>
      </Stack>
    </Box>
  );
};

export default GuidelinesPage;
