import * as React from "react";

export function Color(props) {
  return (
    <svg
      width={28}
      height={26}
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.672 1.414C5.855-.6 2.38.835 1.127 2.797c-.41.64-.7 1.807-.69 2.732.023 1.904 1.077 4.025 3.35 6.165 2.798 2.633 5.653 2.403 6.56 2.182 1.143-.279 1.91-.516 1.792-1.582-.015-.14-.135-.394-.316-.505-.581-.352-1.795-.535-2.366-1.384-.83-1.237.202-1.299 1.19-.77 1.06.566 1.664.92 2.727.822.779-.071 1.911-.541 2.065-1.855.138-1.189-1.597-4.988-5.767-7.188m1.558 6.46c-.292.445-1.015.293-1.488-.127-.474-.42-.535-1.12-.228-1.395.312-.279.9-.215 1.374.205.472.42.571.967.342 1.317z"
        fill="#AC5810"
      />
      <path
        d="M9.839.961C6.021-1.053 2.537.505 1.285 2.466.031 4.43.576 8.062 3.954 11.241c2.797 2.632 5.652 2.403 6.56 2.181 1.143-.278 1.949-.822 1.446-1.516-.347-.479-1.588-.84-2.337-1.955-.83-1.236.203-1.299 1.192-.77 1.059.566 1.664.92 2.726.823.779-.072 1.957-.674 1.921-1.892-.036-1.196-1.453-4.95-5.623-7.15zm1.756 6.879c-.356.385-1.115.28-1.695-.233-.578-.513-.76-1.24-.403-1.625.356-.385 1.115-.28 1.695.233.58.512.76 1.241.403 1.625z"
        fill="#F2A259"
      />
      <path
        d="M5.952 9.583c.144-.368 1.167-.77 2.107-.102.263.188.593.665.56 1.265-.11 1.96-3.327.528-2.667-1.163z"
        fill="#EEE"
      />
      <path
        d="M2.968 6.258c.329-.525 1.128-.585 1.826-.131.623.403 1.143 1.397.375 2.047-.411.349-1.122.133-1.561-.204-.532-.406-.994-1.147-.64-1.712z"
        fill="#2686C6"
      />
      <path
        d="M7.21 1.678c.102-.416.808-.488 1.308-.301.864.322 1.255 1.177.936 1.653-.466.697-1.482.458-1.867.06-.654-.676-.461-1.063-.376-1.412z"
        fill="#FFEE58"
      />
      <path
        d="M12.214 3.715c.692.315.782.813.65 1.177-.083.225-.251.534-.596.562-.584.047-.665-.435-1.082-.568-.35-.111-.492-.59-.355-.911.128-.296.5-.663 1.383-.26z"
        fill="#7CB342"
      />
      <path
        d="M8.373 11.12c-.173.157-.772.349-1.532-.126a1.55 1.55 0 01-.642-.745c-.109-.275-.085-.54-.036-.657.14-.34.353-.402.353-.402-.215-.015-.62.27-.688.644-.022.118-.173.852.8 1.518.84.575 2.08.522 1.998-.636a.899.899 0 01-.253.404z"
        fill="#B0B0B0"
      />
      <path
        d="M5.534 7.215c-.024.447-.547.682-.838.683-.824 0-1.564-.517-1.615-1.438-.009-.165.073-.403.073-.403-.109.046-.263.28-.295.413-.193.795.608 1.722 1.452 1.91.428.094.967-.052 1.163-.435.14-.272.112-.506.06-.73z"
        fill="#01579B"
      />
      <path
        d="M8.415 3.09c-.56-.064-.87-.43-.95-.514-.224-.233-.36-.459-.28-.794a.756.756 0 01.16-.322c-.09.03-.238.226-.285.332-.047.106-.22.505.15.996.229.305.628.65 1.218.732.418.058.719-.092.969-.335.17-.167.245-.542.143-.735.017.141-.242.741-1.125.64z"
        fill="#DF7F14"
      />
      <path
        d="M13.021 4.607a1.201 1.201 0 00-.212-.395c.064.122.19.49-.075.847-.134.18-.537.401-1.011.016-.14-.114-.253-.226-.506-.319-.64-.236-.361-.888-.138-1.091a.784.784 0 00-.4.505c-.093.349.136.714.483.889.395.199.523.582 1.064.582l.088-.003c.365-.031.518-.173.63-.345.136-.215.14-.46.077-.687"
        fill="#558B2F"
      />
      <path
        d="M3.229 2.224c.285-.282.78-.58 1.507-.2.438.227.947.495.716 1.42-.142.573-.977.877-1.675.238-.276-.252-1.326-.69-.548-1.458z"
        fill="#F44336"
      />
      <path
        d="M5.619 3.32c.009-.219-.104-.485-.143-.575.007.13.12.462-.4.9a.865.865 0 01-.87.128c-.183-.072-.447-.376-.636-.452-.701-.282-.399-1.034-.3-1.136a.85.85 0 00-.344.547c-.065.34-.005.66.46 1.025 1.32 1.041 1.725.414 1.87.254.18-.195.353-.45.363-.691z"
        fill="#C62828"
      />
      <path
        d="M8.219 1.42c-.183-.086-.708-.06-.81.377-.061.262.132.528.313.497.206-.035.096-.356.22-.447.188-.138.535-.305.277-.427zm-3.872.638c-.181-.12-.76-.121-.934.322-.105.267.059.575.255.57.224-.005.116-.268.302-.436.235-.211.63-.289.377-.456zM4.26 6.063c-.172-.109-.68-.152-.89.246-.153.292.058.615.242.606.209-.01.139-.342.272-.417.204-.114.616-.283.376-.435zM7.176 9.39c-.183-.086-.709-.06-.81.377-.061.262.131.529.313.497.206-.035.096-.356.22-.447.187-.138.534-.305.277-.427zm4.71-5.635c-.165-.117-.686-.186-.863.227-.107.247.034.544.218.545.209 0 .158-.334.296-.402.209-.1.58-.205.349-.37z"
        fill="#fff"
      />
    </svg>
  );
}
