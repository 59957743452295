/**
 * @file CartPage.jsx
 * @author Rishikesh
 * @date 2024-10-30
 * @description Cart Page for user
 */
import {
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Icons } from "../../../assets";
import {
  decrementQuantity,
  incrementQuantity,
  removeFromCart,
} from "../../../store/features/cartSlice";
import { useResponsivePadding } from "../../../components/hooks/useResponsivePadding";
import { useNavigate } from "react-router-dom";
import { lightTheme } from "../../../components/constants/theme";

const CartPage = () => {
  const dispatch = useDispatch();
  const padding = useResponsivePadding();
  const navigate = useNavigate();

  const { cart } = useSelector((state) => state.cart);
  const currency = useSelector((state) => state.settings.currency);

  return (
    <Box px={padding} py={4}>
      <Stack spacing={2}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h4" fontWeight={700}>
            Shopping Cart
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            disabled={cart.length === 0}
            sx={{ borderRadius: "8px", textTransform: "none" }}
            onClick={() => navigate("/checkout", { state: { product: cart } })}
          >
            Proceed to Checkout
          </Button>
        </Stack>
        <Stack gap={2}>
          {/* if no items in cart */}
          {cart.length === 0 && (
            <Stack
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="100%"
            >
              <Typography variant="h6" fontWeight={600}>
                No items in cart
              </Typography>
            </Stack>
          )}
          {cart?.map((item) => (
            <Card
              key={item.orderitemuuid}
              variant="outlined"
              sx={{
                display: "flex",
                flexDirection: "column",
                mb: 1,
                borderRadius: "8px",
                p: 2,
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                pb={1}
              >
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Box>
                    {item.canvasDataArray.length !== 1 ? (
                      <Icons.Image />
                    ) : (
                      <img
                        src={item.canvasDataArray?.[0]?.imageData}
                        alt="preview"
                        style={{
                          width: 72,
                          height: 72,
                          objectFit: "contain",
                        }}
                      />
                    )}
                  </Box>
                  <Stack spacing={0.5}>
                    <Typography variant="h6" fontWeight={600}>
                      {item.productname || "Product Name"}
                    </Typography>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent={"space-between"}
                      sx={{
                        border: "1px solid ",
                        borderColor: "secondary.main",
                        borderRadius: "50px",
                        p: 0.2,
                      }}
                    >
                      <IconButton
                        onClick={() =>
                          dispatch(decrementQuantity(item.orderitemuuid))
                        }
                      >
                        <Icons.Minus
                          color={lightTheme.palette.secondary.main}
                          strokeWidth={4}
                          width={16}
                          height={16}
                        />
                      </IconButton>
                      <Typography
                        variant="body1"
                        fontWeight={500}
                        borderRadius="50%"
                        bgcolor="secondary.main"
                        px={item.quantity.toString().length === 1 ? 1.5 : 1}
                        py={item.quantity.toString().length === 3 ? 1 : 0.5}
                        color="white"
                      >
                        {item.quantity}
                      </Typography>
                      <IconButton
                        onClick={() =>
                          dispatch(incrementQuantity(item.orderitemuuid))
                        }
                      >
                        <Icons.Plus
                          color={lightTheme.palette.secondary.main}
                          strokeWidth={4}
                          width={16}
                          height={16}
                        />
                      </IconButton>
                    </Stack>
                  </Stack>
                </Stack>
                <Stack alignItems="end" minWidth={100}>
                  <IconButton
                    color="error"
                    onClick={() => dispatch(removeFromCart(item.orderitemuuid))}
                  >
                    <Icons.Delete />
                  </IconButton>
                  <Typography variant="h6" fontWeight={500}>
                    Total: {currency} {item.price * item.quantity}
                  </Typography>
                </Stack>
              </Stack>
              <Divider />
              <Button
                sx={{ textTransform: "none", alignSelf: "end", p: 0, mt: 1 }}
                onClick={() =>
                  navigate("/checkout", { state: { product: [item] } })
                }
              >
                Buy Now
              </Button>
            </Card>
          ))}
        </Stack>
      </Stack>
    </Box>
  );
};

export default CartPage;
