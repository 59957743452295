/**
 * @file ErrorBoundary.jsx
 * @author Rishikesh
 * @date 2024-10-10
 * @description Show this UI if any error crash happens in the app.
 */
import React, { Component } from "react";
import { Typography, Container, Button, Box } from "@mui/material";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught by ErrorBoundary:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container
          maxWidth="md"
          sx={{
            display: "flex",
            width: "100vh",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            backgroundColor: "#f5f5f5",
            borderRadius: 2,
            boxShadow: 3,
            padding: 3,
          }}
        >
          <Typography variant="h4" gutterBottom>
            Oops! Something went wrong.
          </Typography>
          <Typography variant="body1" align="center" sx={{ marginBottom: 3 }}>
            We are sorry for the inconvenience. Please try again later or go
            back to the homepage.
          </Typography>
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                window.location.replace(`/${process.env.REACT_APP_BASE_NAME}`)
              }
              sx={{ mr: 2 }}
            >
              Go to Home
            </Button>

            <Button variant="outlined" onClick={() => window.location.reload()}>
              Refresh Page
            </Button>
          </Box>
        </Container>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
