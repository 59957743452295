/**
 * @file DialogProvider.jsx
 * @author Rishikesh
 * @date 2024-12-09
 * @description Context used to manage the dialog state in the whole app.
 */
import React, { createContext, useContext, useState } from "react";
import { DialogBox } from "../common/DialogBox";

// Create Context
const DialogContext = createContext();

export const useDialog = () => useContext(DialogContext);

export const DialogProvider = ({ children }) => {
  const [dialogState, setDialogState] = useState({
    open: false,
    title: "",
    content: null,
    actions: null,
  });

  const openDialog = ({ title, content, actions }) => {
    setDialogState({ open: true, title, content, actions });
  };

  const closeDialog = () => {
    setDialogState((prevState) => ({ ...prevState, open: false }));
  };

  return (
    <DialogContext.Provider value={{ dialogState, openDialog, closeDialog }}>
      {children}
      <DialogBox />
    </DialogContext.Provider>
  );
};
