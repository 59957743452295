/**
 * @file SummaryPage.jsx
 * @author Rishikesh
 * @date 2024-10-15
 * @description Page shows the summary of the seleted product
 */
import {
  Box,
  Button,
  Card,
  Divider,
  Grid2,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { v4 as uuidv4 } from "uuid";

import { addToCart } from "../../../store/features/cartSlice";
import { useResponsivePadding } from "../../../components/hooks/useResponsivePadding";
import { clearTempImage } from "../../../store/features/tempImageSlice";

const SummaryPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const padding = useResponsivePadding();
  const { enqueueSnackbar } = useSnackbar();

  const currency = useSelector((state) => state.settings.currency);
  const tempImage = useSelector((state) => state.tempImage.tempImage);

  const { productDetails } = location.state || {};

  React.useEffect(() => {
    if (productDetails === undefined) {
      navigate("/", { replace: true });
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddToCart = () => {
    const cartData = {
      orderitemuuid: uuidv4(),
      productuuid: productDetails.product.productuuid,
      quantity: productDetails.quantity,
      productname: productDetails.product.productname,
      price: productDetails.productVariationInfo?.[0]?.price,
      saleprice: productDetails.productVariationInfo?.[0]?.saleprice,
      productdata: productDetails.productVariationInfo?.[0]?.spec,
      canvasDataArray: tempImage,
      bgcolor: productDetails.bgcolor,
    };
    dispatch(addToCart(cartData));
    dispatch(clearTempImage());
    enqueueSnackbar("Added to cart successfully", { variant: "success" });
    navigate("/cart");
  };

  return (
    <Box px={padding} py={4}>
      <Grid2
        container
        spacing={2}
        alignItems={{ xs: "center", md: "start" }}
        justifyContent="center"
        direction={{ xs: "column", md: "row" }}
      >
        {/* Image preview or link to canvas */}
        <Grid2>
          <Box flex={1}>
            <Card
              variant="outlined"
              sx={{
                display: "flex",
                width: {
                  xs: `${187 * 1}px`,
                  sm: `${187 * 2}px`,
                  md: `${187 * 2}px`,
                  lg: `${187 * 2.8}px`,
                },
                height: {
                  xs: `${230 * 1}px`,
                  sm: `${230 * 2}px`,
                  md: `${230 * 2}px`,
                  lg: `${230 * 2.8}px`,
                },
                borderStyle: "dashed",
                borderColor: "secondary.main",
              }}
            >
              <Stack
                flex={1}
                textAlign="center"
                justifyContent="center"
                alignItems="center"
              >
                {tempImage?.length === 0 ? (
                  <>
                    <Typography>
                      Click here to design your{" "}
                      {productDetails.product.productname}
                    </Typography>
                    <Button
                      sx={{ textTransform: "none", mt: 2 }}
                      variant="contained"
                      onClick={() =>
                        navigate("/canvas", { state: { productDetails } })
                      }
                    >
                      Design Now
                    </Button>
                  </>
                ) : (
                  <img
                    src={tempImage?.[0].imageData}
                    alt="preview"
                    style={{
                      width: "90%",
                      height: "90%",
                      objectFit: "contain",
                    }}
                  />
                )}
              </Stack>
            </Card>
          </Box>
        </Grid2>
        {/* Selected specification */}
        <Box flex={1} width={{ xs: "100%", md: "auto" }}>
          <Stack spacing={2}>
            <Card
              variant="outlined"
              sx={{
                backgroundColor: "secondary.main",
                color: "white",
                p: 2,
                borderRadius: "8px",
              }}
            >
              <Stack
                spacing={2}
                display="flex"
                direction="row"
                justifyContent="space-between"
                pb={1}
              >
                <Typography variant="h6" fontWeight={600}>
                  {productDetails.product?.productname || ""}
                </Typography>
                {productDetails?.productVariationInfo?.[0]?.price && (
                  <Stack
                    display="flex"
                    direction="row"
                    alignItems="end"
                    spacing={1}
                  >
                    <Typography variant="h6" fontWeight={600}>
                      {currency}{" "}
                      {productDetails.productVariationInfo[0].price || ""}
                    </Typography>
                    <Typography fontWeight={400}> / piece</Typography>
                  </Stack>
                )}
              </Stack>
              <Divider
                sx={{ borderBottomWidth: 0.5, borderBottomColor: "#A8ACAF" }}
              />
              <Stack pt={1} spacing={2}>
                {Object.keys(productDetails?.selectedSpecification)?.map(
                  (varianceid) => {
                    const { variancename, variationname } =
                      productDetails.selectedSpecification[varianceid];
                    return (
                      <Stack
                        key={varianceid}
                        display="flex"
                        direction="row"
                        spacing={2}
                      >
                        <Typography flex={1}>{variancename}</Typography>
                        <Typography>:</Typography>
                        <Typography flex={1} textAlign="right">
                          {variationname}
                        </Typography>
                      </Stack>
                    );
                  }
                )}
                <Stack display="flex" direction="row" spacing={2}>
                  <Typography flex={1}>Quantity</Typography>
                  <Typography>:</Typography>
                  <Typography flex={1} textAlign="right">
                    {productDetails.quantity}
                  </Typography>
                </Stack>
              </Stack>
            </Card>
            <Button
              variant="outlined"
              sx={{
                borderRadius: "8px",
                textTransform: "none",
                fontSize: 18,
                fontWeight: 500,
                color: "secondary.main",
              }}
              disabled={tempImage?.length === 0}
              color="secondary.main"
              onClick={handleAddToCart}
            >
              Add to cart
            </Button>
          </Stack>
        </Box>
      </Grid2>
    </Box>
  );
};

export default SummaryPage;
