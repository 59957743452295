/**
 * @file DialogueBox.jsx
 * @author Rishikesh
 * @date 2024-12-09
 * @description Dialogue box component of this app.
 */
import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import { useDialog } from "../context/DialogContext";

export const DialogBox = () => {
  const { dialogState, closeDialog } = useDialog();

  return (
    <Dialog
      open={dialogState.open}
      onClose={closeDialog}
      aria-labelledby="dialog-title"
      sx={{
        ".MuiDialog-paper": {
          width: "500px", // Example: Set width
          borderRadius: "16px", // Example: Rounded corners
          padding: "16px", // Example: Inner padding
        },
      }}
    >
      <DialogTitle
        id="dialog-title"
        sx={{ fontSize: "1.5rem", fontWeight: "bold" }}
      >
        {dialogState.title}
      </DialogTitle>
      <DialogContent sx={{ fontSize: "1rem", color: "gray" }}>
        {dialogState.content}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        {dialogState.actions}
      </DialogActions>
    </Dialog>
  );
};
