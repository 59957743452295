/**
 * @file CanvasPage.jsx
 * @author Rishikesh
 * @date 2024-10-15
 * @description Page for customize the product with a canvas.
 */
import { Box, Button, Grid2, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

import { CanvasHeader } from "./CanvasHeader";
import { CanvasTools } from "./CanvasTools";
import { DrawingCanvas } from "./DrawingCanvas";
import { useResponsivePadding } from "../../../components/hooks/useResponsivePadding";
import { setTempImage } from "../../../store/features/tempImageSlice";
import { useDialog } from "../../../components/context/DialogContext";

const CanvasPage = () => {
  const location = useLocation();
  const { productDetails } = location.state || {};
  const navigate = useNavigate();
  const padding = useResponsivePadding();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { openDialog, closeDialog } = useDialog();

  React.useEffect(() => {
    if (productDetails === undefined) {
      navigate("/");
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dimensionjson =
    productDetails?.productVariationInfo?.[0]?.dimensionjson;

  const stageWidthRatio = Math.round(
    (dimensionjson?.stagewidth / 10) * 37.7953
  );
  const stageHeightRatio = Math.round(
    (dimensionjson?.stageheight / 10) * 37.7953
  );
  const radiusRatio = Math.round(((dimensionjson?.radius || 0) / 10) * 37.7953);

  const windowWidth = window.innerWidth * 0.7;
  const windowHeight = window.innerHeight * 0.7;
  const widthScale = windowWidth / stageWidthRatio;
  const heightScale = windowHeight / stageHeightRatio;
  const scaleFactor = Math.min(widthScale, heightScale);
  const stageWidth = stageWidthRatio * scaleFactor;
  const stageHeight = stageHeightRatio * scaleFactor;
  const radius = radiusRatio * scaleFactor;

  const dimensions = {
    shape: dimensionjson?.shape,
    radius,
    width: stageWidth,
    height: stageHeight,
  };

  const noofcanvas =
    productDetails?.productVariationInfo?.[0]?.noofcanvas || [];
  const templateUrl = `data:image/png;base64,${productDetails?.productVariationInfo?.[0].templatepath}`;

  const [selectedTool, setSelectedTool] = useState("");
  const [selectedText, setSelectedText] = useState(null);
  const [images, setImages] = useState([]);
  const [texts, setTexts] = useState([]);
  const [selectedCanvas, setSelectedCanvas] = useState(noofcanvas[0]);
  const [canvasDataArray, setCanvasDataArray] = useState([]);
  const [tools, setTools] = useState([
    { id: 1, icon: "AddImage", name: "Image", isPremium: false },
    { id: 2, icon: "AddText", name: "Text", isPremium: false },
    { id: 3, icon: "AddVector", name: "Vector", isPremium: true },
    { id: 4, icon: "AddShape", name: "Shapes", isPremium: true },
  ]);
  const [bgColor, setBgColor] = useState("#F7FAFC");

  const stageRef = useRef(null);
  const transformerRef = useRef(null);

  const selectedImage = images.find(
    (image) => image.selectedCanvas === selectedCanvas
  );

  const selectedTexts = texts.filter(
    (text) => text.selectedCanvas === selectedCanvas
  );

  const isNotebooks = React.useMemo(
    () =>
      productDetails.product.menuname
        ? productDetails.product.menuname === "Notebooks"
          ? true
          : false
        : productDetails.product.productname === "Notebooks"
        ? true
        : false,
    [productDetails]
  );

  useEffect(() => {
    if (isNotebooks) {
      setTools([
        { id: 1, icon: "AddImage", name: "Image", isPremium: false },
        { id: 2, icon: "AddText", name: "Text", isPremium: false },
        { id: 3, icon: "Color", name: "Bg color", isPremium: false },
        { id: 4, icon: "AddVector", name: "Vector", isPremium: true },
        { id: 5, icon: "AddShape", name: "Shapes", isPremium: true },
      ]);
    }
  }, [isNotebooks]);

  useEffect(() => {
    setSelectedCanvas(noofcanvas[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveCanvasData = (num) => {
    const desiredDPI = 300;
    const defaultDPI = 96;
    const pixelRatio = desiredDPI / defaultDPI;

    if (stageRef.current) {
      const imageData = stageRef.current.toDataURL({
        mimeType: "image/png",
        pixelRatio,
      });

      const rowImage = images.find((image) => image.selectedCanvas === num);
      if (rowImage) {
        fetch(rowImage.imageUrl)
          .then((response) => response.blob())
          .then((blob) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              const base64data = reader.result;
              const existingCanvas = canvasDataArray.find(
                (canvas) => canvas.canvasId === num
              );
              if (existingCanvas) {
                setCanvasDataArray((prevArray) => [
                  ...prevArray.filter((canvas) => canvas.canvasId !== num),
                  { canvasId: num, imageData, rowImage: base64data },
                ]);
              } else {
                setCanvasDataArray((prevArray) => [
                  ...prevArray,
                  { canvasId: num, imageData, rowImage: base64data },
                ]);
              }
            };
            reader.readAsDataURL(blob);
          });
      }
    }
  };

  React.useEffect(() => {
    transformerRef.current.nodes([]);
    saveCanvasData(selectedCanvas);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCanvas, images, texts]);

  const switchCanvas = (num) => {
    transformerRef.current.nodes([]);
    setSelectedCanvas(num);
  };

  const handleSaveAndContinue = () => {
    if (canvasDataArray.length !== noofcanvas.length)
      return enqueueSnackbar("Please fill all the canvas", {
        variant: "error",
      });
    transformerRef.current.nodes([]);
    dispatch(setTempImage(canvasDataArray));
    navigate("/summary", {
      state: {
        productDetails: {
          ...productDetails,
          bgcolor: bgColor,
        },
      },
    });
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return false;
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <Box>
      {/* Canvas header component */}
      <CanvasHeader
        handleBack={() => {
          openDialog({
            title: "Unsaved Changes",
            content: "Your edits will be lost. Do you want to continue?",
            actions: (
              <>
                <Button
                  color="error"
                  onClick={closeDialog}
                  sx={{ textTransform: "none" }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    closeDialog();
                    navigate(-1);
                  }}
                  sx={{ textTransform: "none" }}
                >
                  Proceed
                </Button>
              </>
            ),
          });
        }}
        handleSaveAndContinue={handleSaveAndContinue}
        product={{
          name: productDetails?.product.productname,
          price: productDetails?.productVariationInfo?.[0]?.price,
        }}
      />
      <Box px={padding} pt={2} pb={4}>
        {noofcanvas?.length > 1 && (
          <Stack
            direction={"row"}
            spacing={2}
            mb={2}
            sx={{ overflowX: "scroll" }}
          >
            <Typography variant="h6" sx={{ minWidth: "max-content" }}>
              Canvas -{" "}
            </Typography>
            {noofcanvas.map((num) => (
              <Button
                variant={num === selectedCanvas ? "contained" : "outlined"}
                sx={{ borderRadius: "8px" }}
                key={num}
                onClick={() => switchCanvas(num)}
              >
                <Typography>{num}</Typography>
              </Button>
            ))}
          </Stack>
        )}
        <Grid2 container spacing={4}>
          {/* Canvas tool component */}
          <Grid2>
            <CanvasTools
              stageWidth={stageWidth}
              stageHeight={stageHeight}
              selectedTool={selectedTool}
              setSelectedTool={setSelectedTool}
              images={images}
              setImages={setImages}
              setTexts={setTexts}
              selectedText={selectedText}
              setSelectedText={setSelectedText}
              selectedCanvas={selectedCanvas}
              selectedImage={selectedImage}
              transformerRef={transformerRef}
              bgColor={bgColor}
              setBgColor={setBgColor}
              tools={tools}
            />
          </Grid2>
          {/* Canvas component for editing */}
          <Grid2 flex={{ lg: 1, md: "auto" }}>
            <DrawingCanvas
              numberOfCanvas={productDetails?.product.noofcanvas}
              dimension={dimensions}
              textElements={selectedTexts || []}
              templateImage={templateUrl}
              imageElements={selectedImage ? [selectedImage] : []}
              setSelectedText={setSelectedText}
              setTexts={setTexts}
              setImages={setImages}
              setSelectedTool={setSelectedTool}
              transformerRef={transformerRef}
              stageRef={stageRef}
            />
          </Grid2>
        </Grid2>
      </Box>
    </Box>
  );
};

export default CanvasPage;
