/**
 * @file Menu.jsx
 * @author Rishikesh
 * @date 2024-10-11
 * @description List of menu
 */
import { Button, Stack } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useResponsivePadding } from "../hooks/useResponsivePadding";
import { useDialog } from "../context/DialogContext";

export const Menu = ({ menuList }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const padding = useResponsivePadding();
  const { openDialog, closeDialog } = useDialog();

  const handleNavigation = (item) => {
    if (location.pathname === "/canvas") {
      openDialog({
        title: "Unsaved Changes",
        content: "Your edits will be lost. Do you want to continue?",
        actions: (
          <>
            <Button
              color="error"
              onClick={closeDialog}
              sx={{ textTransform: "none" }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                closeDialog();
                navigate(`/customize/${item.seourl}`, {
                  state: { product: item },
                });
              }}
              sx={{ textTransform: "none" }}
            >
              Proceed
            </Button>
          </>
        ),
      });
    } else {
      navigate(`/customize/${item.seourl}`, {
        state: { product: item },
      });
    }
  };

  return (
    <Stack
      display="flex"
      direction="row"
      bgcolor="background.paper"
      py={1}
      px={padding}
      maxHeight={52}
      spacing={4}
      sx={{
        overflowX: "auto",
        whiteSpace: "nowrap",
        "&::-webkit-scrollbar": { display: "none" },
      }}
    >
      {menuList.map((item) => (
        <Button
          key={item.productuuid}
          sx={{
            textTransform: "none",
            minWidth: "fit-content",
          }}
          onClick={() => handleNavigation(item)}
        >
          {item.menuname}
        </Button>
      ))}
    </Stack>
  );
};
