/**
 * @file HomePage.jsx
 * @author Rishikesh
 * @date 2024-10-10
 * @description Home page of the app.
 */
import {
  Box,
  Button,
  Card,
  CardActionArea,
  Divider,
  Grid,
  Grid2,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";

import { Icons, Images } from "../../../assets";
import { useFetchData } from "../../../components/hooks/useFetchData";
import { useResponsivePadding } from "../../../components/hooks/useResponsivePadding";
import CustomSkeleton from "../../../components/common/CustomSkeleton";
import { DraggableFab } from "../../../components/common/DraggableFab";

const productDetails = [
  {
    productuuid: "ca8c13d1-3111-4db3-bcc3-057d3d84d850",
    title: "With Personalized Notebooks",
    step_description:
      "Hold on to all your picture-perfect moments with our customized notebooks in just 3 simple steps.",
    step_list: [
      "Select notebook specs",
      "Upload the images for notebook covers",
      "Pay for your order",
    ],
    image: Images.Notebook,
  },
  {
    productuuid: "8f002207-13bf-4ffd-a429-a5fde6add275",
    title: "Personalized Photo Puzzle",
    step_description:
      "Hold on to all your picture-perfect moments with our personalized puzzle in just 3 simple steps.",
    step_list: [
      "Select the shape of puzzle",
      "Upload the image",
      "Pay for your order",
    ],
    image: Images.Puzzle,
  },
  {
    productuuid: "96aa9ba9-6795-420d-aa92-5bd187fe0863",
    title: "Personalized Photo Ludo",
    step_description:
      "Hold on to all your picture-perfect moments with our personalized photo ludo in just 2 simple steps.",
    step_list: ["Upload the images", "Pay for your order"],
    image: Images.Ludo,
  },
  {
    productuuid: "8f002207-13bf-4ffd-a429-a5fde6add274",
    title: "Personalized Photo Playing Cards",
    step_description:
      "Hold on to all your picture-perfect moments with our personalized playing cards in just 2 simple steps.",
    step_list: ["Upload the images", "Pay for your order"],
    image: Images.PlayingCards,
  },
  {
    productuuid: "c9104122-48bd-4ad9-afe9-6b0abaddf01d",
    title: "Personalized Photo Memory Game",
    step_description:
      "Hold on to all your picture-perfect moments with our personalized memory game in just 2 simple steps.",
    step_list: ["Upload the images", "Pay for your order"],
    image: Images.MemoryGame,
  },
];

const testimonials = [
  {
    image:
      "https://s3-alpha-sig.figma.com/img/8619/1886/f096dfef156701ede9f1e821038e8931?Expires=1734912000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=cSx~MwQGbCImmVNToYi4TxhBwhTWKZzaanVvorrC~5-0FQnSnfjGPOlIWSZhVHOmdfOUFoHdvNIxP5nt6nE0q8JTHmdXBXA9AsEZPSdPq6JeZIqSuOTwawo87h3gJWEHlECrH1ti90SmTTxksGuYyRdWVlIr0~A2Yb5NsFQQRSx8EsNWcx4VEMdrMD7yQhR1fL6DRqz6sI3UUO0rsTXa80UyGnuq94NALvjLYJ1Fe-GjarUHmyw6MlaHpyXtytWScNJvJ0REfVlkZhGRPk~TmZbKo3gcLIWHIBu3lKQUu7vzPDutkXQmjA8m~xVPnDPKBgQTEAn~YzzAYRIsO97dnQ__",
    name: "Jackson",
    review:
      "I really didn’t have a clue on how to personalize a notebook for a gifting choice. Thanks to Al - sahra, it was real quick and got delivered in no time at all!",
  },
  {
    image:
      "https://s3-alpha-sig.figma.com/img/36fc/5be1/43daa3aa89419d4dfe262bae9371d480?Expires=1734912000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dTujxw9k3MzD9fA6WUsIWghOaUU-8lNL3Emyh6wAImTnc-DLB1Ki12ThSVMWDc2xgu0WNt5aCG22Zg2PKjBf6zYn4rdW4fWQA1XN6BplEkkcO5EjERth7pYbH7phuClrF0WJQCTy8cdzmkloljpt2Ob2Rl3B6qRzemNVvFCHDCbJYPgWpYZfljifVZeVDl9L4rIPOFI64cA8-W7uonY12ysGjQWPshlMVYln~VHsR8Dh0yByc77tP5r5Eu0M391HxlZb8t1X4RHqJJLxi92exMDMvLBwftfxmjBH-c1xHE4GoQJCYwHERA0fgQMdasD~RcERuvkOrEnHk7U6IXrOmg__",
    name: "Amelia",
    review:
      "Loved the look of my new Ludo! The customization process was so easy and I would highly recommend your website to anyone who likes to design their Ludo.",
  },
  {
    image:
      "https://images.pexels.com/photos/29654702/pexels-photo-29654702/free-photo-of-black-and-white-silhouette-of-woman-s-profile.jpeg?auto=compress&cs=tinysrgb&w=800",
    name: "Oliver",
    review:
      "Very good website. The product I ordered was exactly what I wanted. I would definitely recommend this website to anyone looking for a unique gift. Thank you so much!",
  },
];

const HomePage = () => {
  const navigate = useNavigate();
  const padding = useResponsivePadding();
  const { fetchData, loading } = useFetchData();

  const [minLoading, setMinLoading] = useState(true);
  const [productList, setProductList] = useState([]);

  const getProductList = async () => {
    const result = await fetchData("user/products");
    if (result?.code === 200) {
      setProductList(result.data);
    }
  };

  React.useEffect(() => {
    const minLoadingTimer = setTimeout(() => {
      setMinLoading(false);
    }, 1500);

    return () => clearTimeout(minLoadingTimer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  React.useEffect(() => {
    getProductList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = minLoading || loading["user/products"];

  return (
    <Box flex={1} display="flex" flexDirection="column">
      {/* Banner */}
      <Box width="100%" pb={8}>
        {isLoading ? (
          <CustomSkeleton width="100%" height={{ xs: 300, sm: 400, md: 500 }} />
        ) : (
          <img
            src={Images.NotebookBanner}
            alt="Notebook"
            width="100%"
            height="100%"
            style={{ objectFit: "fill", cursor: "pointer" }}
            onClick={() => {
              navigate(`/customize/${productList[0]?.seourl}`, {
                state: {
                  product: productList[0],
                },
              });
            }}
          />
        )}
      </Box>

      <Box
        flex={1}
        display="flex"
        px={padding}
        overflow={"hidden"}
        flexDirection="column"
        pb={5}
      >
        {/* Listing of popular products */}
        <Box width="100%">
          <Typography variant="h5" fontWeight={600} mb={2}>
            Popular Products
          </Typography>
          <Grid2
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12, lg: 15 }}
          >
            {isLoading
              ? Array.from(new Array(5)).map((_, index) => (
                  <Grid2 key={index} size={{ xs: 2, sm: 3, md: 3 }}>
                    <CustomSkeleton
                      width={230}
                      height={{ xs: 280, sm: 300, md: 320, lg: 360 }}
                      iconHeight={238 / 2}
                      iconWidth={181 / 2}
                    />
                  </Grid2>
                ))
              : productList.map((item, index) => (
                  <Grid2 key={item.productuuid} size={{ xs: 2, sm: 3, md: 3 }}>
                    <Card
                      sx={{
                        minHeight: { xs: 280, sm: 300, md: 320, lg: 360 },
                        maxWidth: 290,
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <CardActionArea
                        onClick={() =>
                          navigate(`/customize/${item.seourl}`, {
                            state: { product: item },
                          })
                        }
                      >
                        <Box
                          display="flex"
                          width="100%"
                          height={{
                            xl: 208,
                            lg: 204,
                            md: 200,
                            sm: 180,
                            xs: 160,
                          }}
                          justifyContent="center"
                          alignItems="center"
                          mb={2}
                        >
                          {item.defaultimage ? (
                            <img
                              src={item.defaultimage}
                              alt={item.productname}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                              }}
                            />
                          ) : (
                            <Icons.Image />
                          )}
                        </Box>
                        <Divider />
                        <Box
                          flex={1}
                          display="flex"
                          height={{ xs: 50, sm: 50, md: 60, lg: 70 }}
                          justifyContent="center"
                          alignItems="center"
                          textAlign="center"
                          mt={2}
                        >
                          <Typography fontWeight={500}>
                            {item.productname}
                          </Typography>
                        </Box>
                      </CardActionArea>
                    </Card>
                  </Grid2>
                ))}
          </Grid2>
        </Box>
      </Box>

      {/* Product details */}
      <Box width="100%" py={8} bgcolor="background.paper">
        {isLoading ? (
          <CustomSkeleton width="100%" height="500px" />
        ) : (
          <>
            <Typography variant="h5" fontWeight={400} mb={2} textAlign="center">
              Revisit Your Cherished Memories
            </Typography>
            {productDetails.map((item, index) => (
              <Stack
                key={index}
                gap={4}
                py={index === 0 ? 0 : 8}
                px={padding}
                bgcolor={
                  index % 2 === 0 ? "background.paper" : "background.default"
                }
              >
                <Typography
                  variant="h4"
                  fontWeight={600}
                  mb={2}
                  textAlign="center"
                >
                  {item.title}
                </Typography>
                <Grid container spacing={4} alignItems="center">
                  <Grid
                    item
                    xs={12}
                    md={6}
                    order={{ xs: 1, md: index % 2 === 0 ? 0 : 1 }}
                  >
                    <Box>
                      <img
                        src={item.image}
                        alt="Sample"
                        style={{
                          width: "100%",
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h5" fontWeight={500} mb={2}>
                      {item.step_description}
                    </Typography>
                    {item.step_list.map((step, index) => (
                      <Typography
                        key={index}
                        variant="h6"
                        fontWeight={400}
                        mb={2}
                      >
                        {index + 1}. {step}
                      </Typography>
                    ))}
                    <Button
                      variant="contained"
                      size="large"
                      sx={{
                        mt: 2,
                        textTransform: "none",
                        borderRadius: "8px",
                        px: 8,
                      }}
                      onClick={() => {
                        const matchingProduct = productList.find(
                          (product) => product.productuuid === item.productuuid
                        );

                        if (matchingProduct) {
                          navigate(`/customize/${matchingProduct.seourl}`, {
                            state: { product: matchingProduct },
                          });
                        } else {
                          console.error("Product not found in product list!");
                        }
                      }}
                    >
                      Design Now
                    </Button>
                  </Grid>
                </Grid>
              </Stack>
            ))}
          </>
        )}
      </Box>

      {/* Testimonials */}
      <Box width="100%" py={8} px={padding}>
        <Typography variant="h5" fontWeight={400} textAlign="center">
          Overwhelming Words
        </Typography>
        <Typography variant="h4" fontWeight={500} textAlign="center">
          From Our Customers
        </Typography>
        <Box display="flex" alignItems="center" justifyContent="center" py={8}>
          <Box width="100%">
            <Swiper
              spaceBetween={30}
              breakpoints={{
                600: {
                  slidesPerView: 1,
                },
                900: {
                  slidesPerView: 2,
                },
              }}
              autoplay={{
                delay: 3500,
                disableOnInteraction: false,
              }}
              parallax={true}
              className="mySwiper"
              modules={[Autoplay]}
            >
              {testimonials.map((item, index) => (
                <SwiperSlide key={index}>
                  <Stack direction="row" alignItems="center" gap={4}>
                    <img
                      src={item.image}
                      alt="Sample"
                      style={{
                        width: "187px",
                        height: "187px",
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                    />
                    <Box>
                      <Typography variant="h6" fontWeight={500} mt={2}>
                        {item.name}
                      </Typography>
                      <Typography variant="body1" fontWeight={400} mt={1}>
                        {item.review}
                      </Typography>
                    </Box>
                  </Stack>
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>
        </Box>
      </Box>
      {!isLoading && (
        <DraggableFab
          draggable
          fabStyle={{ backgroundColor: "#25D366" }}
          // onClick={() => {
          //   window.open(
          //     "https://wa.me/919999999999?text=Hello%20there!",
          //     "_blank"
          //   );
          // }}
        >
          <Icons.Whatsapp width={50} height={50} />
        </DraggableFab>
      )}
    </Box>
  );
};

export default HomePage;
