/**
 * @file authSlice.js
 * @author Rishikesh
 * @date 2024-10-10
 * @description Authentication management of user and admin
 */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {
    isAuthenticated: false,
    info: null,
    ssid: null,
  },
  admin: {
    isAuthenticated: false,
    info: null,
    ssid: null,
  },
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    userSignIn: (state, { payload: { user, ssid } }) => {
      state.user = { isAuthenticated: true, info: user, ssid };
    },
    userSignOut: (state) => {
      state.user = {
        isAuthenticated: false,
        info: null,
        ssid: null,
      };
    },
    adminSignIn: (state, { payload: { user, ssid } }) => {
      state.admin = {
        isAuthenticated: true,
        info: user,
        ssid: ssid,
      };
    },
    adminSignOut: (state) => {
      state.admin = {
        isAuthenticated: false,
        info: null,
        ssid: null,
      };
    },
    setUserInfo: (state, { payload }) => {
      state.user.info = payload;
    },
  },
});

export const {
  userSignIn,
  userSignOut,
  adminSignIn,
  adminSignOut,
  setUserInfo,
} = authSlice.actions;
export default authSlice.reducer;
