/**
 * @file CanvasTools.jsx
 * @author Rishikesh
 * @date 2024-10-16
 * @description Component of the canvas list the tools used by canvas sections
 */
import {
  Box,
  Button,
  Card,
  CardActionArea,
  Divider,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useSnackbar } from "notistack";

import { Icons } from "../../../assets";
import { useContrastTextColor } from "../../../components/hooks/useContrastTextColor";

const colorPallet = ["#142C5C", "#F7FAFC", "#DC5A5A", "#319795", "#9E7400"];
// prettier-ignore
const fontFamilies = ["Arial", "Arial Black", "Times New Roman", "Courier New", "Georgia"];
const fontSizes = [12, 14, 16, 18, 20, 22, 24];

export const CanvasTools = ({
  stageWidth,
  stageHeight,
  selectedTool,
  setSelectedTool,
  images,
  setImages,
  selectedText,
  setTexts,
  setSelectedText,
  selectedCanvas,
  selectedImage,
  transformerRef,
  bgColor,
  setBgColor,
  tools = [],
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const textColor = useContrastTextColor(bgColor);

  const fileRef = React.useRef(null);
  const [textProperties, setTextProperties] = useState({
    value: selectedText?.text || "",
    size: selectedText?.size || 14,
    color: selectedText?.color || "#142C5C",
    font: selectedText?.font || "Arial",
    style: {
      bold: selectedText?.style?.includes("bold") || false,
      italic: selectedText?.style?.includes("italic") || false,
    },
  });

  React.useEffect(() => {
    setTextProperties({
      value: selectedText?.text || "",
      size: selectedText?.size || 14,
      color: selectedText?.color || "#142C5C",
      font: selectedText?.font || "Arial",
      style: {
        bold: selectedText?.style?.includes("bold") || false,
        italic: selectedText?.style?.includes("italic") || false,
      },
    });
  }, [selectedText]);

  const onImportImageSelect = React.useCallback(
    (e) => {
      const file = e.target.files?.[0];
      if (file) {
        const imageUrl = URL.createObjectURL(file);
        const img = new Image();
        img.src = imageUrl;

        const validTypes = ["image/png", "image/jpeg"];
        if (!validTypes.includes(file.type)) {
          return enqueueSnackbar("Only PNG and JPG files are supported", {
            variant: "error",
          });
        }

        img.onload = () => {
          const originalWidth = img.width;
          const originalHeight = img.height;

          const widthScale = stageWidth / originalWidth;
          const heightScale = stageHeight / originalHeight;
          const scaleFactor = Math.min(widthScale, heightScale);

          const scaledWidth = originalWidth * scaleFactor;
          const scaledHeight = originalHeight * scaleFactor;

          const newImage = {
            imageUrl,
            img,
            selectedCanvas,
            x: (stageWidth - scaledWidth) / 2,
            y: (stageHeight - scaledHeight) / 2,
            width: scaledWidth,
            height: scaledHeight,
          };

          // If there is an existing selected image, replace it; otherwise, add as a new image
          setImages((prevImages) => {
            const existingImageIndex = prevImages.findIndex(
              (image) => image.selectedCanvas === selectedCanvas
            );
            if (existingImageIndex > -1) {
              const updatedImages = [...prevImages];
              updatedImages[existingImageIndex] = newImage;
              return updatedImages;
            }
            return [...prevImages, newImage];
          });
        };
      }
      e.target.files = null;
    },
    [stageWidth, stageHeight, selectedCanvas, setImages, enqueueSnackbar]
  );

  const onImportImageClick = React.useCallback(() => {
    fileRef?.current?.click();
  }, []);

  const onRemoveImage = () => {
    setImages((prevImages) =>
      prevImages.filter((image) => image.selectedCanvas !== selectedCanvas)
    );
    transformerRef.current.nodes([]);
  };

  const handleTextPropertyChange = (field) => (event) => {
    const value = event.target.value;
    setTextProperties((prev) => ({ ...prev, [field]: value }));
  };

  const handleTextStyleToggle = (style) => {
    setTextProperties((prev) => ({
      ...prev,
      style: {
        ...prev.style,
        [style]: !prev.style[style],
      },
    }));
  };

  const onApplyText = () => {
    if (!textProperties.value.trim()) {
      return null;
    }
    const newText = {
      id: uuidv4(),
      selectedCanvas,
      text: textProperties.value,
      size: textProperties.size,
      style: `${textProperties.style.bold ? "bold" : ""} ${
        textProperties.style.italic ? "italic" : ""
      }`.trim(),
      color: textProperties.color,
      font: textProperties.font,
      x: selectedText?.x || stageWidth / 2,
      y: selectedText?.y || stageHeight / 2,
    };

    if (selectedText) {
      setTexts((prevTexts) =>
        prevTexts.map((text) => (text.id === selectedText.id ? newText : text))
      );
    } else {
      setTexts((prevTexts) => [...prevTexts, newText]);
    }
    transformerRef.current.nodes([]);
    setSelectedText();
  };

  const removeText = () => {
    if (selectedText) {
      setTexts((prevTexts) =>
        prevTexts.filter((text) => text.id !== selectedText.id)
      );
      transformerRef.current.nodes([]);
      setSelectedText();
    }
  };

  return (
    <Box display="flex" justifyContent="end">
      <Box
        sx={{
          width: "380px",
          p: 2,
          borderRadius: "8px",
        }}
        bgcolor="background.paper"
        alignSelf="start"
      >
        <Stack spacing={2}>
          {/* Tabs for specified tool */}
          <Stack display="flex" direction="row" spacing={2}>
            <Button
              variant="contained"
              sx={{
                px: 4,
                py: 1.5,
                bgcolor: "#DC5A5A",
                color: "white",
                flex: 1,
              }}
            >
              Customizer
            </Button>
            <Stack flex={1} textAlign="center">
              <Typography fontWeight={500}>Templates</Typography>
              <Typography fontSize={10} textTransform="uppercase">
                coming soon
              </Typography>
            </Stack>
          </Stack>
          <Divider />
          {/* List of editing content */}
          <Stack
            display="flex"
            flexWrap="wrap"
            direction="row"
            justifyContent="space-between"
            gap={2}
          >
            {tools?.length > 0 &&
              tools?.map((item) => (
                <Card
                  key={item.id}
                  variant="outlined"
                  sx={{
                    width: "74px",
                    height: "69px",
                    borderRadius: "8px",
                    display: "flex",
                    position: "relative",
                    borderColor: selectedTool === item.name ? "success" : "",
                    bgcolor: selectedTool === item.name ? "#EAF5F4" : "",
                    boxShadow: selectedTool === item.name ? 1 : "none",
                  }}
                >
                  <CardActionArea
                    disabled={item.isPremium}
                    sx={{ flex: 1 }}
                    onClick={() => setSelectedTool(item.name)}
                  >
                    <Stack flex={1} alignItems="center" justifyContent="center">
                      {React.createElement(Icons[item.icon])}
                      <Typography>{item.name}</Typography>
                    </Stack>
                  </CardActionArea>
                  {/* Showing the premium items */}
                  {item.isPremium && (
                    <Box
                      position="absolute"
                      width="100%"
                      height="100%"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      bgcolor="#F1F5F980"
                    >
                      <Icons.Premium />
                    </Box>
                  )}
                </Card>
              ))}
          </Stack>
          <Divider />
          {/* Listing of tools based on selected editing content */}
          <Box
            display="flex"
            pt={1}
            alignItems="center"
            justifyContent="center"
          >
            {selectedTool === "Image" && (
              <Box width="100%">
                {/* Addding new image if there is no images else showing the added image and managing */}
                {images?.length === 0 || selectedImage === undefined ? (
                  <Stack
                    spacing={2}
                    alignItems="center"
                    display="flex"
                    justifyContent="center"
                  >
                    <Card
                      variant="outlined"
                      sx={{
                        borderRadius: "8px",
                        borderStyle: "dashed",
                        display: "flex",
                      }}
                    >
                      <CardActionArea
                        sx={{
                          flex: 1,
                          px: 4,
                          py: 2,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={onImportImageClick}
                      >
                        <Icons.ImageUpload />
                        <Typography>Upload Image</Typography>
                        <input
                          ref={fileRef}
                          type="file"
                          onChange={onImportImageSelect}
                          accept="image/*"
                          style={{ display: "none" }}
                        />
                      </CardActionArea>
                    </Card>
                    <Typography color="error">*png,jpg</Typography>
                  </Stack>
                ) : (
                  <Stack
                    spacing={4}
                    display="flex"
                    direction="row"
                    alignItems="center"
                    width="100%"
                  >
                    <img
                      src={selectedImage?.imageUrl}
                      height="125px"
                      width="125px"
                      alt="addedimage"
                    />
                    <Stack spacing={2}>
                      <Button
                        startIcon={<Icons.Replace />}
                        onClick={onImportImageClick}
                      >
                        Replace
                      </Button>
                      <input
                        ref={fileRef}
                        type="file"
                        onChange={onImportImageSelect}
                        accept="image/*"
                        style={{ display: "none" }}
                      />
                      <Button
                        color="error"
                        startIcon={<Icons.Delete />}
                        onClick={onRemoveImage}
                      >
                        Remove
                      </Button>
                    </Stack>
                  </Stack>
                )}
              </Box>
            )}

            {/* Adding a Text and managing it */}
            {selectedTool === "Text" && (
              <Stack display="flex" width="100%">
                {/* Text content */}
                <>
                  <Typography pb={1} fontWeight={500} color="secondary.main">
                    Enter your text
                  </Typography>
                  <TextField
                    placeholder="Type here..."
                    value={textProperties.value}
                    onChange={handleTextPropertyChange("value")}
                    sx={{
                      "& .MuiInputBase-input": {
                        m: 0,
                        p: "4px 8px",
                        minWidth: 120,
                      },
                      "& .MuiOutlinedInput-root": {
                        mt: 0,
                        borderRadius: "8px",
                        height: 42,
                      },
                    }}
                  />
                </>
                {/* Text color managment */}
                <>
                  <Typography
                    pt={2}
                    pb={1}
                    fontWeight={500}
                    color="secondary.main"
                  >
                    Choose color
                  </Typography>
                  <Stack display="flex" direction="row" spacing={2}>
                    {colorPallet.map((color, index) => (
                      <Box
                        key={index}
                        sx={{
                          width: "45px",
                          height: "45px",
                          borderRadius: "50%",
                          bgcolor: color,
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() =>
                          setTextProperties((prev) => ({
                            ...prev,
                            color: color,
                          }))
                        }
                      >
                        {textProperties.color === color && (
                          <Icons.Checkmark color={textColor} />
                        )}
                      </Box>
                    ))}
                    <Box
                      sx={{
                        width: "45px",
                        height: "45px",
                        borderRadius: "50%",
                        background:
                          "conic-gradient(red, yellow, lime, cyan, blue, magenta, red)",
                        cursor: "pointer",
                        position: "relative",
                      }}
                      onClick={() =>
                        document.getElementById("gradient-color-picker").click()
                      }
                    >
                      <input
                        id="gradient-color-picker"
                        type="color"
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          opacity: 0,
                          cursor: "pointer",
                        }}
                        onChange={handleTextPropertyChange("color")}
                      />
                    </Box>
                  </Stack>
                </>
                {/* Text style managment */}
                <>
                  <Typography
                    pt={2}
                    pb={1}
                    fontWeight={500}
                    color="secondary.main"
                  >
                    Choose font and styles
                  </Typography>
                  <Stack display="flex" direction="row" spacing={2}>
                    <TextField
                      sx={{
                        flex: 2,
                        "& .MuiInputBase-input": {
                          m: 0,
                          p: "4px 8px",
                          minWidth: 120,
                        },
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "8px",
                          height: 42,
                        },
                      }}
                      size="small"
                      select
                      defaultValue="Arial"
                      variant="outlined"
                      placeholder="Select"
                      value={textProperties.font}
                      onChange={handleTextPropertyChange("font")}
                    >
                      {fontFamilies.map((font) => (
                        <MenuItem key={font} value={font}>
                          {font}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      sx={{
                        flex: 1,
                        "& .MuiInputBase-input": {
                          m: 0,
                          p: "4px 8px",
                          minWidth: 120,
                        },
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "8px",
                          height: 42,
                        },
                      }}
                      size="small"
                      select
                      defaultValue={12}
                      variant="outlined"
                      placeholder="Select"
                      value={textProperties.size}
                      onChange={handleTextPropertyChange("size")}
                    >
                      {fontSizes.map((value) => (
                        <MenuItem key={value} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </TextField>
                    <IconButton
                      sx={{
                        width: 46,
                        height: 46,
                        border: "1px solid #A8ACAF",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        borderRadius: "8px",
                        bgcolor: textProperties.style.bold ? "#D8DCE1" : "",
                      }}
                      onClick={() => handleTextStyleToggle("bold")}
                    >
                      <Icons.Bold />
                    </IconButton>
                    <IconButton
                      sx={{
                        width: 46,
                        height: 46,
                        border: "1px solid #A8ACAF",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        borderRadius: "8px",
                        bgcolor: textProperties.style.italic ? "#D8DCE1" : "",
                      }}
                      onClick={() => handleTextStyleToggle("italic")}
                    >
                      <Icons.Italic />
                    </IconButton>
                  </Stack>
                </>
                {/* Button for apply the details to text */}
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: "8px",
                    mt: 2,
                    py: 2,
                    fontSize: 14,
                    textTransform: "none",
                  }}
                  onClick={onApplyText}
                >
                  Apply
                </Button>
                {/* Remove the text */}
                <Button
                  sx={{
                    borderRadius: "8px",
                    mt: 1,
                    fontSize: 14,
                    textTransform: "none",
                  }}
                  disableRipple
                  startIcon={<Icons.Delete />}
                  onClick={removeText}
                >
                  Remove
                </Button>
              </Stack>
            )}

            {/* Adding Background Color */}
            {selectedTool === "Bg color" && (
              <Box mt={2}>
                <Stack display="flex" direction="row" spacing={2}>
                  {colorPallet.map((color, index) => (
                    <Box
                      key={index}
                      sx={{
                        width: "45px",
                        height: "45px",
                        borderRadius: "50%",
                        bgcolor: color,
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        setBgColor(color);
                      }}
                    >
                      {bgColor === color && (
                        <Icons.Checkmark
                          color={color === "#F7FAFC" ? "black" : "white"}
                        />
                      )}
                    </Box>
                  ))}
                  <Box
                    sx={{
                      width: "45px",
                      height: "45px",
                      borderRadius: "50%",
                      background:
                        "conic-gradient(red, yellow, lime, cyan, blue, magenta, red)",
                      cursor: "pointer",
                      position: "relative",
                    }}
                    onClick={() =>
                      document.getElementById("gradient-color-picker").click()
                    }
                  >
                    <input
                      id="gradient-color-picker"
                      type="color"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      onChange={(e) => setBgColor(e.target.value)}
                    />
                  </Box>
                </Stack>
                <Box
                  mt={4}
                  width="100%"
                  height="150px"
                  borderRadius="8px"
                  bgcolor={bgColor}
                  justifyContent="center"
                  alignItems="center"
                  display="flex"
                  border={`1px solid ${textColor}`}
                >
                  <Typography color={textColor}>
                    Selected Background Color
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};
