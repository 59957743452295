/**
 * @file index.js
 * @author Rishikesh
 * @date 2024-10-10
 * @description root file of the app.
 */
import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, GlobalStyles } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";

import "./index.css";
import { store } from "./store/store";
import { lightTheme } from "./components/constants/theme";
import { DrawerProvider } from "./components/context/DrawerContext";
import { router } from "./routes";
import { UserAuthModalProvider } from "./components/context/UserAuthModalContext";
import AuthModal from "./pages/user/auth/AuthModal";
import { DialogProvider } from "./components/context/DialogContext";

Sentry.init({
  dsn: `${process.env.REACT_APP_SENTRY_DSN}`,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/sprints\.ae\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
const globalStyles = {
  a: {
    color: "unset",
    textDecoration: "none",
  },
};
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={lightTheme}>
        <SnackbarProvider maxSnack={3}>
          <DialogProvider>
            <UserAuthModalProvider>
              <DrawerProvider>
                <CssBaseline />
                <GlobalStyles styles={globalStyles} />
                <RouterProvider router={router} />
                <AuthModal />
              </DrawerProvider>
            </UserAuthModalProvider>
          </DialogProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);
