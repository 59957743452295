/**
 * @file useContrastTextColor.jsx
 * @author Rishikesh
 * @date 2024-11-19
 * @description Hook used to manage the contrast text color of the app.
 */
import { useState, useEffect } from "react";

/**
 * Custom hook to return the appropriate text color for good contrast
 * based on the background color.
 * If the background color is dark, it returns a light text color.
 * If the background color is light, it returns a dark text color.
 * @param {string} bgColor - The background color in hex format (e.g., '#000000' or '#ffffff').
 * @returns {string} - The appropriate text color based on the background color.
 */
export const useContrastTextColor = (bgColor) => {
  const [textColor, setTextColor] = useState("#142C5C"); // Default text color

  useEffect(() => {
    /**
     * Calculate luminance of a color to determine if it's dark or light.
     * @param {string} hex - The background color in hex format.
     * @returns {boolean} - True if the color is dark, false if it's light.
     */
    const isDarkColor = (hex) => {
      hex = hex.replace("#", "");
      let r = parseInt(hex.substring(0, 2), 16);
      let g = parseInt(hex.substring(2, 4), 16);
      let b = parseInt(hex.substring(4, 6), 16);

      const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
      return luminance < 128; // Threshold to determine if it's dark
    };

    if (isDarkColor(bgColor)) {
      setTextColor("#ffffff"); // If background is dark, set text to white
    } else {
      setTextColor("#142C5C"); // If background is light, set text to dark
    }
  }, [bgColor]);

  return textColor;
};
