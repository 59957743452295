/**
 * @file DraggableFab.jsx
 * @author Rishikesh
 * @date 2024-12-10
 * @description Component used to make the floating action button draggable.
 */
import React, { useState, useEffect } from "react";
import { Fab } from "@mui/material";

export const DraggableFab = ({
  draggable = false,
  children,
  fabStyle,
  ...props
}) => {
  const [position, setPosition] = useState({
    x: window.innerWidth - 80,
    y: window.innerHeight - 80,
  });
  const [dragging, setDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  const Left_MARGIN = 16;
  const TOP_MARGIN = 128;

  useEffect(() => {
    const handleResize = () => {
      setPosition((prev) => ({
        x: Math.min(prev.x, window.innerWidth),
        y: Math.min(prev.y, window.innerHeight - 60),
      }));
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleStart = (e) => {
    if (draggable) {
      e.preventDefault();
      e.stopPropagation();
      const clientX =
        e.type === "touchstart" ? e.touches[0].clientX : e.clientX;
      const clientY =
        e.type === "touchstart" ? e.touches[0].clientY : e.clientY;

      setDragging(true);
      setOffset({
        x: clientX - position.x,
        y: clientY - position.y,
      });
    }
  };

  const handleMove = (e) => {
    if (draggable && dragging) {
      e.preventDefault();
      e.stopPropagation();
      const clientX = e.type === "touchmove" ? e.touches[0].clientX : e.clientX;
      const clientY = e.type === "touchmove" ? e.touches[0].clientY : e.clientY;

      const newX = clientX - offset.x;
      const newY = clientY - offset.y;
      setPosition({
        x: Math.max(0, Math.min(newX, window.innerWidth - 60)),
        y: Math.max(TOP_MARGIN, Math.min(newY, window.innerHeight - 80)),
      });
    }
  };

  const handleEnd = () => {
    if (draggable && dragging) {
      setDragging(false);
      setPosition((prev) => ({
        x:
          prev.x < window.innerWidth / 2 ? Left_MARGIN : window.innerWidth - 76,
        y: Math.max(TOP_MARGIN, prev.y),
      }));
    }
  };

  useEffect(() => {
    if (draggable && dragging) {
      document.addEventListener("mousemove", handleMove);
      document.addEventListener("mouseup", handleEnd);
      document.addEventListener("touchmove", handleMove, { passive: false });
      document.addEventListener("touchend", handleEnd);
    } else {
      document.removeEventListener("mousemove", handleMove);
      document.removeEventListener("mouseup", handleEnd);
      document.removeEventListener("touchmove", handleMove);
      document.removeEventListener("touchend", handleEnd);
    }

    return () => {
      document.removeEventListener("mousemove", handleMove);
      document.removeEventListener("mouseup", handleEnd);
      document.removeEventListener("touchmove", handleMove);
      document.removeEventListener("touchend", handleEnd);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dragging, draggable]);

  return (
    <Fab
      style={{
        position: "fixed",
        top: `${position.y}px`,
        left: `${position.x}px`,
        zIndex: 1000,
        transition: dragging ? "none" : "top 0.3s ease, left 0.3s ease",
        padding: 0,
        ...fabStyle,
      }}
      onMouseDown={handleStart}
      onTouchStart={handleStart}
      {...props}
    >
      {children}
    </Fab>
  );
};
