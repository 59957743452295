/**
 * @file CheckoutPage.jsx
 * @author Rishikesh
 * @date 2024-12-12
 * @description CheckoutPage page for the user
 */
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { BufferLoader } from "../../../components/common/BufferLoader";
import { useResponsivePadding } from "../../../components/hooks/useResponsivePadding";
import { useSnackbar } from "notistack";
import { useUserAuthModal } from "../../../components/context/UserAuthModalContext";
import { useDispatch, useSelector } from "react-redux";
import { removeFromCart } from "../../../store/features/cartSlice";
import { useFetchData } from "../../../components/hooks/useFetchData";
import { base64ToFile } from "../../../components/hooks/usebase64ToFile";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";

const CheckoutPage = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { fetchData, loading } = useFetchData();
  const { openAuthModal } = useUserAuthModal();
  const padding = useResponsivePadding();
  const location = useLocation();
  const { product } = location.state;

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      addressline: "",
      city: "",
      state: "",
      pincode: "",
      country: "",
    },
  });

  const [shippingType, setShippingType] = React.useState("Take Away");
  const [address, setAddress] = React.useState(null);
  const [countryList, setCountryList] = React.useState([]);
  const [selectedAddress, setSelectedAddress] = React.useState(null);

  const user = useSelector((state) => state.auth.user);
  const currency = useSelector((state) => state.settings.currency);

  const grandTotal = product.reduce((total, item) => {
    return total + (item.price || 0) * item.quantity;
  }, 0);

  const getCountryList = async () => {
    const result = await fetchData("user/getcountries");
    if (result?.code === 200) {
      setCountryList(result.data);
    }
  };

  const getAddress = async () => {
    const result = await fetchData("user/getaddress");
    if (result?.code === 200) {
      setAddress(result.data);
    }
  };

  React.useEffect(() => {
    if (user) {
      getAddress();
      getCountryList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleCreateAddress = async (data) => {
    const formatData = {
      addresses: [{ ...data, addressuuid: "", isdefault: 0, isdelete: 0 }],
    };
    const result = await fetchData("user/manageaddress", {
      method: "post",
      body: formatData,
    });
    if (result?.code === 200) {
      enqueueSnackbar("Address created successfully", { variant: "success" });
      getAddress();
      reset();
    } else {
      enqueueSnackbar("Failed to create address", { variant: "error" });
    }
  };

  const handleUploadImageFiles = async (item) => {
    if (!item) return;

    const image = product.find((i) => i.orderitemuuid === item.orderitemuuid);
    let allImagesUploaded = true;

    for (const canvas of image.canvasDataArray) {
      const { canvasId, rowImage, imageData } = canvas;
      const imagesToUpload = [
        { base64Image: rowImage, imageType: "rawimage" },
        { base64Image: imageData, imageType: "userimage" },
      ];

      for (const { base64Image, imageType } of imagesToUpload) {
        if (!base64Image) continue;

        const fileName = `image_${item.orderitemuuid}_${canvasId}_${imageType}.png`;
        const imageFile = base64ToFile(base64Image, fileName);

        const formData = new FormData();
        formData.append("image", imageFile, fileName);
        formData.append("orderuuid", item.orderuuid);
        formData.append("orderitemuuid", item.orderitemuuid);
        formData.append("imagetype", imageType);
        formData.append("canvasnumber", canvasId);

        try {
          const result = await fetchData("user/upload-image", {
            method: "post",
            body: formData,
          });

          if (!(result?.code === 200 || result?.code === 201)) {
            enqueueSnackbar("Image upload failed", { variant: "error" });
            allImagesUploaded = false;
            break;
          }
        } catch (error) {
          enqueueSnackbar("Image upload error", { variant: "error" });
          allImagesUploaded = false;
          break;
        }
      }
    }

    if (allImagesUploaded) {
      dispatch(removeFromCart(item.orderitemuuid));
    }
    return allImagesUploaded;
  };

  const handleCheckoutPage = async () => {
    if (!user.isAuthenticated) return openAuthModal();

    const updatedCart = product.map((item) => ({
      ...item,
      canvasDataArray: [],
    }));

    const body = {
      addressuuid: selectedAddress?.addressuuid || null,
      orders: updatedCart,
    };
    const orderResult = await fetchData("user/order", {
      method: "post",
      body: JSON.stringify(body),
    });

    if (orderResult?.code === 200 || orderResult?.code === 201) {
      let allUploadsSuccessful = true;

      if (Array.isArray(orderResult.data)) {
        for (const item of orderResult.data) {
          const uploadSuccess = await handleUploadImageFiles(item);
          if (!uploadSuccess) {
            allUploadsSuccessful = false;
            break;
          }
        }
      }

      if (allUploadsSuccessful) {
        // All images uploaded, proceed with payment API
        try {
          const paymentResult = await fetchData("user/create-payment-link", {
            method: "post",
            body: JSON.stringify({
              orderuuid: orderResult.data?.[0].orderuuid,
            }),
          });

          if (paymentResult?.code === 200 || paymentResult?.code === 201) {
            window.location.replace(paymentResult?.data?.sessionUrl);
          } else {
            enqueueSnackbar("Payment failed", { variant: "error" });
          }
        } catch (error) {
          enqueueSnackbar("Payment error", { variant: "error" });
        }
      } else {
        enqueueSnackbar(
          "CheckoutPage completed, but some images failed to upload",
          {
            variant: "warning",
          }
        );
      }
    } else {
      enqueueSnackbar("CheckoutPage failed", { variant: "error" });
    }
  };

  const isLoading = loading["user/order"] || loading["user/upload-image"];

  return (
    <Box px={padding} py={4}>
      <Stack spacing={2}>
        <Typography variant="h4" fontWeight={700}>
          Shopping Cart
        </Typography>
        <Stack direction={{ xs: "column", md: "row" }} gap={2}>
          <Card
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              minHeight: { xs: "100%", md: window.innerHeight - "50%" },
              height: "auto",
              p: 2,
              borderRadius: "8px",
              boxShadow: "none",
            }}
          >
            <Typography>Choose delivery type before pay</Typography>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              value={shippingType}
              onChange={(e) => setShippingType(e.target.value)}
            >
              <Stack direction="row" flexWrap="wrap" spacing={2}>
                <FormControlLabel
                  value="Take Away"
                  control={<Radio />}
                  label="Take Away"
                />
                <FormControlLabel
                  value="Shipping"
                  control={<Radio />}
                  label="Shipping"
                />
              </Stack>
            </RadioGroup>
            <Divider />
            {shippingType === "Shipping" && (
              <Box flex={1} display="flex" flexDirection="column" py={1}>
                <Stack gap={2}>
                  {address?.length > 0 ? (
                    <>
                      <Typography>Deliver to: </Typography>
                      <Stack direction="row" flexWrap="wrap" spacing={2}>
                        {address.map((item) => (
                          <Box
                            key={item.addressuuid}
                            sx={{
                              border: "1px solid #E0E0E0",
                              borderRadius: "8px",
                              p: 2,
                              cursor: "pointer",
                              bgcolor:
                                selectedAddress?.addressuuid ===
                                item.addressuuid
                                  ? "#E0E0E0"
                                  : "transparent",
                              "&:hover": {
                                bgcolor: "#E0E0E0",
                              },
                            }}
                            onClick={() => setSelectedAddress(item)}
                          >
                            <Stack spacing={1}>
                              <Typography variant="body1" fontWeight={500}>
                                {item.addressname}
                              </Typography>
                              <Typography variant="body1" fontWeight={500}>
                                {item.address}
                              </Typography>
                              <Typography variant="body1" fontWeight={500}>
                                {item.city}, {item.state}, {item.country}
                              </Typography>
                              <Typography variant="body1" fontWeight={500}>
                                {item.pincode}
                              </Typography>
                            </Stack>
                          </Box>
                        ))}
                      </Stack>
                    </>
                  ) : (
                    <AddressForm
                      onSubmit={handleSubmit(handleCreateAddress)}
                      register={register}
                      errors={errors}
                      countries={countryList}
                      watch={watch}
                      setValue={setValue}
                    />
                  )}
                </Stack>
              </Box>
            )}
          </Card>
          <Box
            display="flex"
            flexDirection="column"
            minHeight={{ xs: "100%", md: window.innerHeight - 200 - 80 }}
            borderLeft={{ xs: 0, md: "1px solid #E0E0E0" }}
            sx={{
              bgcolor: "transparent",
              px: 2,
            }}
          >
            <Stack spacing={2} display="flex" flexDirection="column" flex={1}>
              <Stack display="flex" spacing={2} flex={1}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="body1" color="text.secondary">
                    Grand Total
                  </Typography>
                  <Typography variant="body1" fontWeight={500}>
                    {currency} {grandTotal.toFixed(2)}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="body1" color="text.secondary">
                    Discount
                  </Typography>
                  <Typography variant="body1" fontWeight={500}>
                    {currency} 0.00
                  </Typography>
                </Stack>
              </Stack>
              <Divider />
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={4}
              >
                <Typography fontWeight={600}>Amount Payable</Typography>
                <Typography fontWeight={600}>
                  {currency} {grandTotal.toFixed(2)}
                </Typography>
              </Stack>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                disabled={product.length === 0}
                sx={{ borderRadius: "8px", textTransform: "none" }}
                onClick={handleCheckoutPage}
              >
                Proceed to Pay
              </Button>
            </Stack>
          </Box>
        </Stack>
      </Stack>
      <BufferLoader
        loading={isLoading}
        text="Please wait we are uploading your images. Do not refresh or close the browser until the uploading is completed."
      />
    </Box>
  );
};

const AddressForm = ({
  onSubmit,
  register,
  errors,
  countries,
  watch,
  setValue,
}) => (
  <Box component="form" onSubmit={onSubmit} flex={1}>
    <Stack spacing={1} mt={2}>
      <Typography variant="body1" fontWeight={500}>
        Create Address
      </Typography>
      {["addressline", "city", "state", "pincode"].map((field) => (
        <Stack key={field}>
          <Typography variant="body1" fontWeight={500}>
            {field.charAt(0).toUpperCase() + field.slice(1)}
          </Typography>
          <TextField
            size="small"
            placeholder={`Enter ${
              field.charAt(0).toUpperCase() + field.slice(1)
            }`}
            id={field === "addressline" ? "address" : field}
            variant="outlined"
            fullWidth
            required
            type={field === "pincode" ? "number" : "text"}
            {...register(field)}
            helperText={errors[field]?.message}
          />
        </Stack>
      ))}
      <Stack>
        <Typography variant="body1" fontWeight={500}>
          Country
        </Typography>
        <Autocomplete
          id="country"
          size="small"
          fullWidth
          options={countries}
          getOptionLabel={(option) => option.country}
          value={
            countries?.find((option) => option.id === watch("country")) || null
          }
          onChange={(event, option) => setValue("country", option.id)}
          renderInput={(params) => <TextField {...params} />}
        />
      </Stack>
      <Button size="large" type="submit" variant="contained" fullWidth>
        Add Address
      </Button>
    </Stack>
  </Box>
);

export default CheckoutPage;
