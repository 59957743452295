/**
 * @file ScrollToTop.jsx
 * @author Rishikesh
 * @date 2024-11-20
 * @description Component used to manage the scroll to top of the app.
 */
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const { pathname, search } = useLocation();

  useEffect(() => {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [pathname, search]); // Runs whenever the pathname or search changes

  return null;
};

export default ScrollToTop;
