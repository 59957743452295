/**
 * @file ProductDetails.jsx
 * @author Rishikesh
 * @date 2024-10-21
 * @description Product details listing page
 */
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { useFetchData } from "../../../components/hooks/useFetchData";
import { useResponsivePadding } from "../../../components/hooks/useResponsivePadding";

const ProductDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const padding = useResponsivePadding();
  const { fetchData, loading } = useFetchData();

  const [orderDetails, setOrderDetails] = useState([]);

  const currency = useSelector((state) => state.settings.currency);
  const adminSsid = useSelector((state) => state.auth.admin.ssid);

  const orderDetail = async () => {
    if (!id) return;
    const result = await fetchData(`admin/orders/orderdetailsbyUUID/${id}`);
    if (result?.code === 200) {
      setOrderDetails(result.data);
    }
  };

  React.useEffect(() => {
    orderDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const downloadImage = async (orderitemuuid, image) => {
    const baseUrl = process.env.REACT_APP_API_URL;
    const imagePath = `admin/orders/image/${orderDetails.orderuuid}/${orderitemuuid}/${image.imagetype}/${image.canvasnumber}`;
    const urlWithAuth = `${baseUrl}${imagePath}?ssid=${adminSsid}`;

    window.open(urlWithAuth, "_blank");
  };

  const isLoading = loading[`admin/orders/orderdetailsbyUUID/${id}`];

  return (
    <Box px={padding} py={4}>
      <Breadcrumbs>
        <Link onClick={() => navigate("/admin/dashboard", { replace: true })}>
          Dashboard
        </Link>
        <Typography>Order Details</Typography>
      </Breadcrumbs>
      <Stack direction="row" justifyContent="space-between" textAlign="right">
        <Typography variant="h5" sx={{ mt: 2, mb: 4, fontWeight: 600 }}>
          Order Details
        </Typography>
        <Box>
          <Typography>
            Payment Status:{" "}
            <span
              style={{
                color: orderDetails.paymentstatus === 1 ? "green" : "red",
                fontWeight: 600,
              }}
            >
              {orderDetails.paymentstatus === 1 ? "Paid" : "Unpaid"}{" "}
            </span>
          </Typography>
          {orderDetails.receipturl && (
            <Button
              sx={{ p: 0, color: "blue", textTransform: "none" }}
              onClick={() => window.open(orderDetails.receipturl, "_blank")}
            >
              View Receipt
            </Button>
          )}
        </Box>
      </Stack>

      {/* Delivery Address Section */}
      {orderDetails.username && (
        <Card elevation={2} sx={{ my: 3 }}>
          <CardContent>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: 500 }}>
              Delivery Address
            </Typography>
            <Stack spacing={1}>
              <Typography>
                <strong>Name:</strong> {orderDetails.username}
              </Typography>
              <Typography>
                <strong>Address:</strong> {orderDetails.addressline}
              </Typography>
              <Typography>
                <strong>City:</strong> {orderDetails.city}
              </Typography>
              <Typography>
                <strong>State:</strong> {orderDetails.state}
              </Typography>
              <Typography>
                <strong>Country:</strong> {orderDetails.country}
              </Typography>
              <Typography>
                <strong>Pincode:</strong> {orderDetails.pincode}
              </Typography>
            </Stack>
          </CardContent>
        </Card>
      )}

      {/* Order Items Section */}
      <Grid container spacing={4}>
        {isLoading ? (
          <Grid container p={3} spacing={4}>
            {[...Array(3)].map((_, index) => (
              <Grid item xs={12} key={index}>
                <Skeleton
                  variant="rectangular"
                  height={200}
                  sx={{ borderRadius: 2, mb: 2 }}
                />
                <Skeleton variant="text" width="60%" />
                <Skeleton variant="text" width="40%" />
                <Skeleton variant="text" width="80%" />
              </Grid>
            ))}
          </Grid>
        ) : (
          orderDetails?.orderitems?.map((item) => (
            <Grid item xs={12} key={item.orderitemuuid}>
              <Card elevation={3} sx={{ borderRadius: 2, overflow: "hidden" }}>
                <CardContent sx={{ backgroundColor: "#f7f9fc", padding: 3 }}>
                  <Typography variant="h6" sx={{ mb: 2, fontWeight: 500 }}>
                    Product Information
                  </Typography>
                  <Stack spacing={1}>
                    <Typography>
                      <strong>Product Name:</strong> {item.productname}
                    </Typography>
                    <Typography>
                      <strong>Quantity:</strong> {item.quantity}
                    </Typography>
                    <Typography>
                      <strong>Price:</strong> {currency} {item.price} / piece
                    </Typography>
                    <Typography>
                      <strong>Total:</strong> {currency}{" "}
                      {item.price * item.quantity}
                    </Typography>
                    {item.bgcolor && (
                      <Typography>
                        <strong>Background Color:</strong> {item.bgcolor}
                      </Typography>
                    )}
                  </Stack>
                </CardContent>
              </Card>
              {item.formatted_product_data && (
                <Card elevation={2} sx={{ mt: 3 }}>
                  <CardContent>
                    <Typography variant="h6" sx={{ mb: 2, fontWeight: 500 }}>
                      Product Specifications
                    </Typography>
                    <Grid container spacing={2}>
                      {Object.entries(item.formatted_product_data).map(
                        ([key, value]) => (
                          <Grid item xs={12} sm={6} md={3} key={key}>
                            <Paper
                              sx={{
                                p: 2,
                                textAlign: "center",
                                borderRadius: 1,
                                bgcolor: "#eef1f6",
                              }}
                            >
                              <Typography variant="subtitle2" sx={{ mb: 1 }}>
                                {key}
                              </Typography>
                              <Typography variant="body2">{value}</Typography>
                            </Paper>
                          </Grid>
                        )
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              )}
              <Card elevation={2} sx={{ mt: 3 }}>
                <CardContent>
                  <Typography variant="h6" sx={{ mb: 2, fontWeight: 500 }}>
                    Product Images
                  </Typography>
                  {item.images &&
                    Array.from(
                      new Set(item.images.map((image) => image.canvasnumber))
                    ).map((canvasNumber) => (
                      <Box key={canvasNumber} mb={2}>
                        <Typography variant="subtitle2" sx={{ mb: 1 }}>
                          Canvas {canvasNumber}
                        </Typography>
                        <Grid container spacing={2}>
                          {item.images
                            .filter(
                              (img) =>
                                img.imagetype === "rawimage" &&
                                img.canvasnumber === canvasNumber
                            )
                            .map((rawImage, idx) => (
                              <Grid item xs={12} sm={6} key={`raw-${idx}`}>
                                <Button
                                  fullWidth
                                  variant="contained"
                                  onClick={() =>
                                    downloadImage(item.orderitemuuid, rawImage)
                                  }
                                  size="small"
                                  sx={{ textTransform: "none" }}
                                >
                                  Raw Image
                                </Button>
                              </Grid>
                            ))}
                          {item.images
                            .filter(
                              (img) =>
                                img.imagetype !== "rawimage" &&
                                img.canvasnumber === canvasNumber
                            )
                            .map((img, idx) => (
                              <Grid item xs={12} sm={6} key={`img-${idx}`}>
                                <Button
                                  fullWidth
                                  variant="contained"
                                  onClick={() =>
                                    downloadImage(item.orderitemuuid, img)
                                  }
                                  size="small"
                                  sx={{ textTransform: "none" }}
                                >
                                  Download Image
                                </Button>
                              </Grid>
                            ))}
                        </Grid>
                      </Box>
                    ))}
                </CardContent>
              </Card>
            </Grid>
          ))
        )}
      </Grid>
    </Box>
  );
};

export default ProductDetails;
